import React from "react";
import Loader from "react-loader-spinner";

import druid_balance from "../../img/banner/Druid_Balance.png";
import druid_feral_dps from "../../img/banner/Druid_Feral_DPS.png";
import druid_feral_tank from "../../img/banner/Druid_Feral_Tank.png";
import druid_resto from "../../img/banner/Druid_Resto.png";
import hunter from "../../img/banner/Hunter.png";
import mage from "../../img/banner/Mage.png";
import paladin_holy from "../../img/banner/Paladin_Holy.png";
import paladin_protection from "../../img/banner/Paladin_Protection.png";
import paladin_ret from "../../img/banner/Paladin_Ret.png";
import priest_healing from "../../img/banner/Priest_Healing.png";
import priest_shadow from "../../img/banner/Priest_Shadow.png";
import rogue from "../../img/banner/Rogue.png";
import shaman_elemental from "../../img/banner/Shaman_Elemental.png";
import shaman_enhancement from "../../img/banner/Shaman_Enhancement.png";
import shaman_restoration from "../../img/banner/Shaman_Restoration.png";
import warlock from "../../img/banner/Warlock.png";
import warrior_dps from "../../img/banner/Warrior_DPS.png";
import warrior_protection from "../../img/banner/Warrior_Protection.jpg";

function selectBanner(wowspec, wowclass) {
  switch (wowspec) {
    case "druid-feral-bear":
      return druid_feral_tank;
    case "druid-feral-cat":
      return druid_feral_dps;
    case "druid-resto":
      return druid_resto;
    case "druid-balance":
      return druid_balance;
    case "paladin-holy":
      return paladin_holy;
    case "paladin-retribution":
      return paladin_ret;
    case "paladin-protection":
      return paladin_protection;
    case "priest-holy":
      return priest_healing;
    case "priest-shadow":
      return priest_shadow;
    case "rogue-dagger":
      return rogue;
    case "rogue-sword":
      return rogue;
    case "shaman-elemental":
      return shaman_elemental;
    case "shaman-resto":
      return shaman_restoration;
    case "shaman-enhancement":
      return shaman_enhancement;
    case "warrior-fury":
      return warrior_dps;
    case "warrior-prot":
      return warrior_protection;
    case null:
      switch (wowclass) {
        case "hunter":
          return hunter;
        case "mage":
          return mage;
        case "warlock":
          return warlock;
        default:
          return null;
      }
    default:
      return null;
  }
}

function selectLink(wowspec, wowclass) {
  switch (wowspec) {
    case "druid-feral-bear":
      return "https://classic.wowhead.com/guides/feral-druid-tank-classic-wow";
    case "druid-feral-cat":
      return "https://classic.wowhead.com/guides/feral-druid-dps-classic-wow";
    case "druid-resto":
      return "https://classic.wowhead.com/guides/druid-healing-classic-wow";
    case "druid-balance":
      return "https://classic.wowhead.com/guides/balance-druid-dps-classic-wow";
    case "paladin-holy":
      return "https://classic.wowhead.com/guides/paladin-healing-classic-wow";
    case "paladin-retribution":
      return "https://classic.wowhead.com/guides/paladin-dps-classic-wow";
    case "paladin-protection":
      return "https://classic.wowhead.com/guides/lights-bulwark-protection-paladin-tanking";
    case "priest-holy":
      return "https://classic.wowhead.com/guides/priest-healing-classic-wow";
    case "priest-shadow":
      return "https://classic.wowhead.com/guides/shadow-priest-dps-classic-wow";
    case "rogue-dagger":
      return "https://classic.wowhead.com/guides/rogue-dps-classic-wow";
    case "rogue-sword":
      return "https://classic.wowhead.com/guides/rogue-dps-classic-wow";
    case "shaman-elemental":
      return "https://classic.wowhead.com/guides/elemental-shaman-dps-classic-wow";
    case "shaman-resto":
      return "https://classic.wowhead.com/guides/shaman-healing-classic-wow";
    case "shaman-enhancement":
      return "https://classic.wowhead.com/guides/enhancement-shaman-dps-classic-wow";
    case "warrior-fury":
      return "https://classic.wowhead.com/guides/fury-warrior-dps-classic-wow";
    case "warrior-prot":
      return "https://classic.wowhead.com/guides/warrior-tank-classic-wow";
    case null:
      switch (wowclass) {
        case "hunter":
          return "https://classic.wowhead.com/guides/hunter-dps-classic-wow";
        case "mage":
          return "https://classic.wowhead.com/guides/mage-dps-classic-wow";
        case "warlock":
          return "https://classic.wowhead.com/guides/warlock-dps-classic-wow";
        default:
          return null;
      }
    default:
      return null;
  }
}

export default class Banner extends React.Component {
  render() {
    var img_banner = selectBanner(this.props.wowspec, this.props.wowclass);
    var wowhead_link = selectLink(this.props.wowspec, this.props.wowclass);
    return (
      <div className="Banner">
        {img_banner == null ? (
          <div />
        ) : (
          <a href={wowhead_link} target="_blank">
            <img
              src={img_banner}
              alt="wowhead banner guide"
              className="wowhead_banner"
            />
          </a>
        )}
      </div>
    );
  }
}
