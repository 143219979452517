import React from "react";

export default class BisList extends React.Component {
  static data = [
    {
		id: 1,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 1,
		head_item: 14539,	
		neck_item: 13177,
		shoulder_item: 10783,
		back_item: 12551,
		chest_item: 15064,
		wrist_item: 12966,
		hand_item: 13258,
		waist_item: 13252,
		leg_item: 11821,
		foot_item: 16711,
		finger1_item: 15855,
		finger2_item: 11669,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 2,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-cat",
		name: "Druid (feral, cat)",
		stage: 1,
		head_item: 8345,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 14637,
		wrist_item: 16710,
		hand_item: 15063,
		waist_item: 13252,
		leg_item: 15062,
		foot_item: 12553,
		finger1_item: 17713,
		finger2_item: 2246,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 11921,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 3,
		race : null,
		faction: null,
		wowclass: "druid",		
		wowspec: "druid-resto",
		name: "Druid (resto)",
		stage: 1,
		head_item: 13102,
		neck_item: 18723,
		shoulder_item: 15061,
		back_item: 13386,
		chest_item: 13346,
		wrist_item: 13208,
		hand_item: 10787,
		waist_item: 14553,
		leg_item: 11841,
		foot_item: 13954,
		finger1_item: 13178,
		finger2_item: 16058,
		trinket1_item: 12930,
		trinket2_item: 11819,
		mainhand_item: 11923,
		offhand_item: 11928,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 5,
		race : null,
		faction: null,
		wowclass: "hunter",
		wowspec: null,
		name: "Hunter",
		stage: 1,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 13211,
		hand_item: 15063,
		waist_item: 14502,
		leg_item: 15062,
		foot_item: 13967,
		finger1_item: 13098,
		finger2_item: 17713,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12940,
		offhand_item: 12939,
		range_item: 18738,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 12460
	},
	{
		id: 6,
		race : null,
		faction: null,
		wowclass: "mage",
		wowspec: null,
		name: "Mage",
		stage: 1,
		head_item: 10504,
		neck_item: 12103,
		shoulder_item: 11782,
		back_item: 13386,
		chest_item: 14152,
		wrist_item: 11766,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 11822,
		finger1_item: 942,
		finger2_item: 942,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 13964,
		offhand_item: 10796,
		range_item: 13938,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 7,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-holy",
		name: "Paladin (holy)",
		stage: 1,
		head_item: 12633,
		neck_item: 18723,
		shoulder_item: 18720,
		back_item: 13386,
		chest_item: 13346,
		wrist_item: 13969,
		hand_item: 10787,
		waist_item: 18702,
		leg_item: 11841,
		foot_item: 13954,
		finger1_item: 16058,
		finger2_item: 18103,
		trinket1_item: 11819,
		trinket2_item: 12930,
		mainhand_item: 11923,
		offhand_item: 11928,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 8,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-holy",
		name: "Priest (holy)",
		stage: 1,
		head_item: 13102,
		neck_item: 18723,
		shoulder_item: 13013,
		back_item: 13386,
		chest_item: 14154,
		wrist_item: 11766,
		hand_item: 10787,
		waist_item: 14143,
		leg_item: 11841,
		foot_item: 11822,
		finger1_item: 16058,
		finger2_item: 13178,
		trinket1_item: 11819,
		trinket2_item: 12930,
		mainhand_item: 11923,
		offhand_item: 11928,
		range_item: 16997,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 9,
		race : null,
		faction: "horde",
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 1,
		head_item: 10504,
		neck_item: 18691,
		shoulder_item: 14112,
		back_item: 13386,
		chest_item: 14136,
		wrist_item: 11766,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 11819,
		mainhand_item: 13349,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 10,
		race : null,
		faction: "alliance",
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 1,
		head_item: 10504,
		neck_item: 18691,
		shoulder_item: 14112,
		back_item: 13386,
		chest_item: 14136,
		wrist_item: 11766,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12543,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 11819,
		mainhand_item: 13349,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 11,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-sword",
		name: "Rogue (sword)",
		stage: 1,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 14637,
		wrist_item: 13120,
		hand_item: 15063,
		waist_item: 13252,
		leg_item: 15062,
		foot_item: 12553,
		finger1_item: 13098,
		finger2_item: 17713,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12940,
		offhand_item: 12939,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 12,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-dagger",
		name: "Rogue (Dagger)",
		stage: 1,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 14637,
		wrist_item: 13120,
		hand_item: 15063,
		waist_item: 13252,
		leg_item: 15062,
		foot_item: 12553,
		finger1_item: 13098,
		finger2_item: 17713,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12783,
		offhand_item: 14555,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20031,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 13,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-elemental",
		name: "Shaman (elemental)",
		stage: 1,
		head_item: 10504,
		neck_item: 12103,
		shoulder_item: 13013,
		back_item: 15421,
		chest_item: 11924,
		wrist_item: 11765,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 13954,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 13964,
		offhand_item: 11904,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 14,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-resto",
		name: "Shaman (resto)",
		stage: 1,
		head_item: 13216,
		neck_item: 18723,
		shoulder_item: 15061,
		back_item: 13386,
		chest_item: 13346,
		wrist_item: 13969,
		hand_item: 10787,
		waist_item: 14553,
		leg_item: 11841,
		foot_item: 13954,
		finger1_item: 16058,
		finger2_item: 13178,
		trinket1_item: 12930,
		trinket2_item: 11819,
		mainhand_item: 11923,
		offhand_item: 11928,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 15,
		race : null,
		faction: "horde",
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 1,
		head_item: 10504,
		neck_item: 18691,
		shoulder_item: 14112,
		back_item: 13386,
		chest_item: 14153,
		wrist_item: 11766,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 13964,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 16,
		race : null,
		faction: "alliance",
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 1,
		head_item: 10504,
		neck_item: 18691,
		shoulder_item: 14112,
		back_item: 13386,
		chest_item: 14153,
		wrist_item: 11766,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12543,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 13964,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 17,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior (fury)",
		stage: 1,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 14551,
		waist_item: 13142,
		leg_item: 14554,
		foot_item: 14616,
		finger1_item: 17713,
		finger2_item: 13098,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12940,
		offhand_item: 12939,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 113,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Orc (fury)",
		stage: 1,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 15063,
		waist_item: 13959,
		leg_item: 15062,
		foot_item: 12555,
		finger1_item: 17713,
		finger2_item: 13098,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 811,
		offhand_item: 871,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 114,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Human (fury)",
		stage: 1,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 15063,
		waist_item: 13959,
		leg_item: 15062,
		foot_item: 12555,
		finger1_item: 17713,
		finger2_item: 13098,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12940,
		offhand_item: 12939,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 18,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior (prot)",
		stage: 1,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 13397,
		chest_item: 14624,
		wrist_item: 12550,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 11927,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 15806,
		offhand_item: 12602,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 115,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Orc (prot)",
		stage: 1,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 13397,
		chest_item: 14624,
		wrist_item: 12550,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 11927,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 811,
		offhand_item: 12602,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 116,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Human (prot)",
		stage: 1,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 13397,
		chest_item: 14624,
		wrist_item: 12550,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 11927,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 15806,
		offhand_item: 12602,
		range_item: 12651,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 19,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 2,
		head_item: 14539,	
		neck_item: 13177,
		shoulder_item: 10783,
		back_item: 12551,
		chest_item: 15064,
		wrist_item: 12966,
		hand_item: 13258,
		waist_item: 13252,
		leg_item: 11821,
		foot_item: 16711,
		finger1_item: 15855,
		finger2_item: 11669,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 20,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-cat",
		name: "Druid (feral, cat)",
		stage: 2,
		head_item: 8345,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 14637,
		wrist_item: 18375,
		hand_item: 15063,
		waist_item: 13252,
		leg_item: 15062,
		foot_item: 12553,
		finger1_item: 17713,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 18420,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 21,
		race : null,
		faction: null,
		wowclass: "druid",		
		wowspec: "druid-resto",
		name: "Druid (resto)",
		stage: 2,
		head_item: 13102,
		neck_item: 18723,
		shoulder_item: 15061,
		back_item: 18510,
		chest_item: 13346,
		wrist_item: 18525,
		hand_item: 10787,
		waist_item: 14553,
		leg_item: 18386,
		foot_item: 13954,
		finger1_item: 13178,
		finger2_item: 16058,
		trinket1_item: 18470,
		trinket2_item: 18371,
		mainhand_item: 11923,
		offhand_item: 18523,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 22,
		race : null,
		faction: null,
		wowclass: "hunter",
		wowspec: null,
		name: "Hunter",
		stage: 2,
		head_item: 18421,
		neck_item: 15411,
		shoulder_item: 13358,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 13211,
		hand_item: 15063,
		waist_item: 18393,
		leg_item: 15062,
		foot_item: 13967,
		finger1_item: 18500,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 18473,
		mainhand_item: 18520,
		offhand_item: 0,
		range_item: 18738,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 0,
		range_enchant: 12460
	},
	{
		id: 23,
		race : null,
		faction: null,
		wowclass: "mage",
		wowspec: null,
		name: "Mage",
		stage: 2,
		head_item: 23318,
		neck_item: 12103,
		shoulder_item: 23319,
		back_item: 13386,
		chest_item: 14152,
		wrist_item: 11766,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 23304,
		foot_item: 23291,
		finger1_item: 942,
		finger2_item: 942,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 13964,
		offhand_item: 10796,
		range_item: 13938,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 24,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-holy",
		name: "Paladin (holy)",
		stage: 2,
		head_item: 18490,
		neck_item: 18723,
		shoulder_item: 18720,
		back_item: 18510,
		chest_item: 13346,
		wrist_item: 13969,
		hand_item: 18527,
		waist_item: 18702,
		leg_item: 18386,
		foot_item: 18507,
		finger1_item: 16058,
		finger2_item: 18103,
		trinket1_item: 11819,
		trinket2_item: 12930,
		mainhand_item: 11923,
		offhand_item: 18523,
		range_item: 0,
		head_enchant: 15404,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 15404,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 25,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-holy",
		name: "Priest (holy)",
		stage: 2,
		head_item: 13102,
		neck_item: 18723,
		shoulder_item: 13013,
		back_item: 18510,
		chest_item: 14154,
		wrist_item: 11766,
		hand_item: 10787,
		waist_item: 18327,
		leg_item: 18386,
		foot_item: 18507,
		finger1_item: 16058,
		finger2_item: 13178,
		trinket1_item: 18469,
		trinket2_item: 18371,
		mainhand_item: 11923,
		offhand_item: 18523,
		range_item: 18483,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 26,
		race : null,
		faction: "horde",
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 2,
		head_item: 10504,
		neck_item: 18691,
		shoulder_item: 14112,
		back_item: 13386,
		chest_item: 14136,
		wrist_item: 11766,
		hand_item: 18407,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 18371,
		mainhand_item: 13349,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 27,
		race : null,
		faction: "alliance",
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 2,
		head_item: 10504,
		neck_item: 18691,
		shoulder_item: 14112,
		back_item: 13386,
		chest_item: 14136,
		wrist_item: 11766,
		hand_item: 18407,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12543,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 18371,
		mainhand_item: 13349,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 28,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-sword",
		name: "Rogue (sword)",
		stage: 2,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 14637,
		wrist_item: 18375,
		hand_item: 15063,
		waist_item: 13252,
		leg_item: 15062,
		foot_item: 12553,
		finger1_item: 18500,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12940,
		offhand_item: 12939,
		range_item: 18323,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 29,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-dagger",
		name: "Rogue (Dagger)",
		stage: 2,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 14637,
		wrist_item: 18375,
		hand_item: 15063,
		waist_item: 18505,
		leg_item: 15062,
		foot_item: 12553,
		finger1_item: 18500,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12783,
		offhand_item: 14555,
		range_item: 18323,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20031,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 30,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-elemental",
		name: "Shaman (elemental)",
		stage: 2,
		head_item: 10504,
		neck_item: 12103,
		shoulder_item: 23260,
		back_item: 18496,
		chest_item: 18385,
		wrist_item: 11765,
		hand_item: 13253,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18322,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 18534,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 31,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-resto",
		name: "Shaman (resto)",
		stage: 2,
		head_item: 13216,
		neck_item: 18723,
		shoulder_item: 18757,
		back_item: 13386,
		chest_item: 13346,
		wrist_item: 13969,
		hand_item: 18527,
		waist_item: 14553,
		leg_item: 18386,
		foot_item: 13954,
		finger1_item: 16058,
		finger2_item: 13178,
		trinket1_item: 18371,
		trinket2_item: 18371,
		mainhand_item: 11923,
		offhand_item: 18523,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 32,
		race : null,
		faction: "horde",
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 2,
		head_item: 23310,
		neck_item: 18691,
		shoulder_item: 23311,
		back_item: 13386,
		chest_item: 14153,
		wrist_item: 11766,
		hand_item: 18407,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 18467,
		mainhand_item: 18372,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 33,
		race : null,
		faction: "alliance",
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 2,
		head_item: 23310,
		neck_item: 18691,
		shoulder_item: 23311,
		back_item: 13386,
		chest_item: 14153,
		wrist_item: 11766,
		hand_item: 18407,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 18735,
		finger1_item: 12543,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 18467,
		mainhand_item: 18372,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 34,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior (fury)",
		stage: 2,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 14551,
		waist_item: 13142,
		leg_item: 14554,
		foot_item: 14616,
		finger1_item: 17713,
		finger2_item: 13098,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12940,
		offhand_item: 12939,
		range_item: 18323,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 117,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Orc (fury)",
		stage: 2,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 15063,
		waist_item: 13959,
		leg_item: 15062,
		foot_item: 12555,
		finger1_item: 17713,
		finger2_item: 13098,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 811,
		offhand_item: 871,
		range_item: 18323,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 118,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Human (fury)",
		stage: 2,
		head_item: 13404,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 15063,
		waist_item: 13959,
		leg_item: 15062,
		foot_item: 12555,
		finger1_item: 17713,
		finger2_item: 13098,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12940,
		offhand_item: 12939,
		range_item: 18323,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 35,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior (prot)",
		stage: 2,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 18754,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 11927,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 15806,
		offhand_item: 12602,
		range_item: 18323,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 15389,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 119,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Orc (prot)",
		stage: 2,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 18754,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 11927,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 811,
		offhand_item: 12602,
		range_item: 18323,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 15389,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 120,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Human (prot)",
		stage: 2,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 18754,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 11927,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 15806,
		offhand_item: 12602,
		range_item: 18323,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 15389,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 36,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 3,
		head_item: 14539,
		neck_item: 17065,
		shoulder_item: 19139,
		back_item: 17107,
		chest_item: 15064,
		wrist_item: 12966,
		hand_item: 13258,
		waist_item: 19149,
		leg_item: 11821,
		foot_item: 16711,
		finger1_item: 15855,
		finger2_item: 18879,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22846,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 22846,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 37,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-cat",
		name: "Druid (feral, cat)",
		stage: 3,
		head_item: 8345,
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 14637,
		wrist_item: 19146,
		hand_item: 18823,
		waist_item: 13252,
		leg_item: 15062,
		foot_item: 12553,
		finger1_item: 17063,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 18420,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 38,
		race : null,
		faction: null,
		wowclass: "druid",		
		wowspec: "druid-resto",
		name: "Druid (resto)",
		stage: 3,
		head_item: 19132,
		neck_item: 18723,
		shoulder_item: 18810,
		back_item: 18510,
		chest_item: 13346,
		wrist_item: 18263,
		hand_item: 10787,
		waist_item: 19162,
		leg_item: 18875,
		foot_item: 16829,
		finger1_item: 19140,
		finger2_item: 19140,
		trinket1_item: 18470,
		trinket2_item: 17064,
		mainhand_item: 17070,
		offhand_item: 18523,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 39,
		race : null,
		faction: null,
		wowclass: "hunter",
		wowspec: null,
		name: "Hunter",
		stage: 3,
		head_item: 16846,
		neck_item: 18404,
		shoulder_item: 16848,
		back_item: 17102,
		chest_item: 16845,
		wrist_item: 16850,
		hand_item: 16852,
		waist_item: 16851,
		leg_item: 16847,
		foot_item: 16849,
		finger1_item: 17063,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 18473,
		mainhand_item: 18832,
		offhand_item: 18805,
		range_item: 18713,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20036,
		offhand_enchant: 20036,
		range_enchant: 22779
	},
	{
		id: 40,
		race : null,
		faction: null,
		wowclass: "mage",
		wowspec: null,
		name: "Mage",
		stage: 3,
		head_item: 16914,
		neck_item: 18814,
		shoulder_item: 23319,
		back_item: 13386,
		chest_item: 14152,
		wrist_item: 11766,
		hand_item: 13253,
		waist_item: 19136,
		leg_item: 16915,
		foot_item: 23291,
		finger1_item: 19147,
		finger2_item: 19147,
		trinket1_item: 12930,
		trinket2_item: 18820,
		mainhand_item: 17103,
		offhand_item: 10796,
		range_item: 19130,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 41,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-holy",
		name: "Paladin (holy)",
		stage: 3,
		head_item: 18490,
		neck_item: 18723,
		shoulder_item: 18810,
		back_item: 18510,
		chest_item: 19145,
		wrist_item: 13969,
		hand_item: 18527,
		waist_item: 19162,
		leg_item: 18875,
		foot_item: 18507,
		finger1_item: 19140,
		finger2_item: 19140,
		trinket1_item: 17064,
		trinket2_item: 12930,
		mainhand_item: 17103,
		offhand_item: 18523,
		range_item: 0,
		head_enchant: 15404,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 15404,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 42,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-holy",
		name: "Priest (holy)",
		stage: 3,
		head_item: 16921,
		neck_item: 18723,
		shoulder_item: 16816,
		back_item: 18510,
		chest_item: 14154,
		wrist_item: 16819,
		hand_item: 16812,
		waist_item: 16817,
		leg_item: 16922,
		foot_item: 16811,
		finger1_item: 19140,
		finger2_item: 19140,
		trinket1_item: 18469,
		trinket2_item: 17064,
		mainhand_item: 18608,
		offhand_item: 0,
		range_item: 18483,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 43,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 3,
		head_item: 10504,
		neck_item: 18814,
		shoulder_item: 14112,
		back_item: 13386,
		chest_item: 14136,
		wrist_item: 11766,
		hand_item: 18407,
		waist_item: 18809,
		leg_item: 19133,
		foot_item: 19131,
		finger1_item: 19147,
		finger2_item: 19147,
		trinket1_item: 12930,
		trinket2_item: 18820,
		mainhand_item: 18609,
		offhand_item: 0,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 44,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-sword",
		name: "Rogue (sword)",
		stage: 3,
		head_item: 16908,
		neck_item: 18404,
		shoulder_item: 16823,
		back_item: 13340,
		chest_item: 16820,
		wrist_item: 18375,
		hand_item: 16826,
		waist_item: 16827,
		leg_item: 16909,
		foot_item: 16824,
		finger1_item: 17063,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17075,
		offhand_item: 18832,
		range_item: 17069,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 45,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-dagger",
		name: "Rogue (Dagger)",
		stage: 3,
		head_item: 16908,
		neck_item: 18404,
		shoulder_item: 16823,
		back_item: 13340,
		chest_item: 16820,
		wrist_item: 18375,
		hand_item: 18823,
		waist_item: 16827,
		leg_item: 16909,
		foot_item: 16824,
		finger1_item: 17063,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 18816,
		offhand_item: 18805,
		range_item: 17069,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20031,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 46,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-elemental",
		name: "Shaman (elemental)",
		stage: 3,
		head_item: 10504,
		neck_item: 18814,
		shoulder_item: 18829,
		back_item: 18496,
		chest_item: 19145,
		wrist_item: 11765,
		hand_item: 16839,
		waist_item: 19136,
		leg_item: 16946,
		foot_item: 18322,
		finger1_item: 19147,
		finger2_item: 19147,
		trinket1_item: 12930,
		trinket2_item: 18820,
		mainhand_item: 18842,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 47,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-resto",
		name: "Shaman (resto)",
		stage: 3,
		head_item: 16842,
		neck_item: 18723,
		shoulder_item: 18810,
		back_item: 13386,
		chest_item: 13346,
		wrist_item: 13969,
		hand_item: 18527,
		waist_item: 19162,
		leg_item: 18875,
		foot_item: 13954,
		finger1_item: 19140,
		finger2_item: 13178,
		trinket1_item: 17064,
		trinket2_item: 18371,
		mainhand_item: 17070,
		offhand_item: 18523,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 48,
		race : null,
		faction: null,
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 3,
		head_item: 23310,
		neck_item: 18814,
		shoulder_item: 23311,
		back_item: 13386,
		chest_item: 19145,
		wrist_item: 11766,
		hand_item: 18407,
		waist_item: 18809,
		leg_item: 19133,
		foot_item: 19131,
		finger1_item: 19147,
		finger2_item: 19147,
		trinket1_item: 12930,
		trinket2_item: 18820,
		mainhand_item: 17103,
		offhand_item: 10796,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 49,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior (fury)",
		stage: 3,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 14551,
		waist_item: 19137,
		leg_item: 14554,
		foot_item: 14616,
		finger1_item: 17063,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17075,
		offhand_item: 18832,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 121,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Orc (fury)",
		stage: 3,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 15063,
		waist_item: 19137,
		leg_item: 15062,
		foot_item: 12555,
		finger1_item: 17063,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17068,
		offhand_item: 871,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 122,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Human (fury)",
		stage: 3,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 15063,
		waist_item: 19137,
		leg_item: 15062,
		foot_item: 12555,
		finger1_item: 17063,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17075,
		offhand_item: 18832,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 50,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior (prot)",
		stage: 3,
		head_item: 16963,
		neck_item: 17065,
		shoulder_item: 16868,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 16861,
		hand_item: 13072,
		waist_item: 16864,
		leg_item: 16962,
		foot_item: 16862,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 11810,
		trinket2_item: 18406,
		mainhand_item: 18203,
		offhand_item: 17066,
		range_item: 18323,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 22725,
		foot_enchant: 22725,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 123,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Orc (prot)",
		stage: 3,
		head_item: 16963,
		neck_item: 17065,
		shoulder_item: 16868,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 16861,
		hand_item: 13072,
		waist_item: 16864,
		leg_item: 16962,
		foot_item: 16862,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 11810,
		trinket2_item: 18406,
		mainhand_item: 17068,
		offhand_item: 17066,
		range_item: 18323,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 22725,
		foot_enchant: 22725,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 124,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Human (prot)",
		stage: 3,
		head_item: 16963,
		neck_item: 17065,
		shoulder_item: 16868,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 16861,
		hand_item: 13072,
		waist_item: 16864,
		leg_item: 16962,
		foot_item: 16862,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 11810,
		trinket2_item: 18406,
		mainhand_item: 18348,
		offhand_item: 17066,
		range_item: 18323,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 22725,
		foot_enchant: 22725,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 51,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 4,
		head_item: 14539,
		neck_item: 17065,
		shoulder_item: 19389,
		back_item: 19386,
		chest_item: 19405,
		wrist_item: 12966,
		hand_item: 13258,
		waist_item: 19149,
		leg_item: 11821,
		foot_item: 19381,
		finger1_item: 19376,
		finger2_item: 18879,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22846,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 22846,
		foot_enchant: 13890,
		mainhand_enchant: 23800,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 52,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-cat",
		name: "Druid (feral, cat)",
		stage: 4,
		head_item: 8345,
		neck_item: 19377,
		shoulder_item: 12927,
		back_item: 19436,
		chest_item: 19405,
		wrist_item: 19146,
		hand_item: 18823,
		waist_item: 19396,
		leg_item: 15062,
		foot_item: 19381,
		finger1_item: 19384,
		finger2_item: 19432,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19358,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 23799,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 53,
		race : null,
		faction: null,
		wowclass: "druid",		
		wowspec: "druid-resto",
		name: "Druid (resto)",
		stage: 4,
		head_item: 19132,
		neck_item: 18723,
		shoulder_item: 18810,
		back_item: 19430,
		chest_item: 13346,
		wrist_item: 16904,
		hand_item: 16899,
		waist_item: 19162,
		leg_item: 19385,
		foot_item: 16898,
		finger1_item: 19140,
		finger2_item: 19382,
		trinket1_item: 19395,
		trinket2_item: 17064,
		mainhand_item: 19360,
		offhand_item: 19312,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23802,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 54,
		race : null,
		faction: null,
		wowclass: "hunter",
		wowspec: null,
		name: "Hunter",
		stage: 4,
		head_item: 16939,
		neck_item: 19377,
		shoulder_item: 16937,
		back_item: 17102,
		chest_item: 16942,
		wrist_item: 16935,
		hand_item: 16940,
		waist_item: 16936,
		leg_item: 16938,
		foot_item: 16941,
		finger1_item: 19325,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 19406,
		mainhand_item: 18832,
		offhand_item: 18805,
		range_item: 19361,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 23800,
		offhand_enchant: 23800,
		range_enchant: 22779
	},
	{
		id: 55,
		race : null,
		faction: null,
		wowclass: "mage",
		wowspec: null,
		name: "Mage",
		stage: 4,
		head_item: 19375,
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19378,
		chest_item: 14152,
		wrist_item: 19374,
		hand_item: 16913,
		waist_item: 19136,
		leg_item: 16915,
		foot_item: 19438,
		finger1_item: 19403,
		finger2_item: 19147,
		trinket1_item: 19379,
		trinket2_item: 19339,
		mainhand_item: 19356,
		offhand_item: 0,
		range_item: 19130,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 56,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-holy",
		name: "Paladin (holy)",
		stage: 4,
		head_item: 19375,
		neck_item: 18723,
		shoulder_item: 18810,
		back_item: 19430,
		chest_item: 19145,
		wrist_item: 13969,
		hand_item: 19390,
		waist_item: 19162,
		leg_item: 19385,
		foot_item: 19437,
		finger1_item: 19382,
		finger2_item: 19140,
		trinket1_item: 17064,
		trinket2_item: 19395,
		mainhand_item: 19360,
		offhand_item: 19312,
		range_item: 0,
		head_enchant: 15404,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 15404,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 57,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-holy",
		name: "Priest (holy)",
		stage: 4,
		head_item: 16921,
		neck_item: 18723,
		shoulder_item: 16924,
		back_item: 19430,
		chest_item: 16923,
		wrist_item: 16926,
		hand_item: 16920,
		waist_item: 16925,
		leg_item: 16922,
		foot_item: 16919,
		finger1_item: 19382,
		finger2_item: 19140,
		trinket1_item: 19395,
		trinket2_item: 17064,
		mainhand_item: 18608,
		offhand_item: 0,
		range_item: 19435,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 58,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 4,
		head_item: 19375,
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19378,
		chest_item: 14136,
		wrist_item: 19374,
		hand_item: 19407,
		waist_item: 19400,
		leg_item: 19133,
		foot_item: 19131,
		finger1_item: 19403,
		finger2_item: 19434,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19360,
		offhand_item: 19366,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 59,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-sword",
		name: "Rogue (sword)",
		stage: 4,
		head_item: 16908,
		neck_item: 19377,
		shoulder_item: 16832,
		back_item: 19398,
		chest_item: 16905,
		wrist_item: 16911,
		hand_item: 16907,
		waist_item: 16910,
		leg_item: 16909,
		foot_item: 16906,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19352,
		offhand_item: 19351,
		range_item: 17069,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 60,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-dagger",
		name: "Rogue (Dagger)",
		stage: 4,
		head_item: 16908,
		neck_item: 19377,
		shoulder_item: 16823,
		back_item: 19398,
		chest_item: 16905,
		wrist_item: 16911,
		hand_item: 18823,
		waist_item: 16910,
		leg_item: 16909,
		foot_item: 19381,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 18816,
		offhand_item: 18805,
		range_item: 17069,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20031,
		offhand_enchant: 23800,
		range_enchant: 0
	},
	{
		id: 61,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-elemental",
		name: "Shaman (elemental)",
		stage: 4,
		head_item: 19375,
		neck_item: 18814,
		shoulder_item: 18829,
		back_item: 19378,
		chest_item: 19145,
		wrist_item: 19374,
		hand_item: 16839,
		waist_item: 19400,
		leg_item: 16946,
		foot_item: 18322,
		finger1_item: 19397,
		finger2_item: 19403,
		trinket1_item: 19379,
		trinket2_item: 19344,
		mainhand_item: 19360,
		offhand_item: 19366,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 62,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-resto",
		name: "Shaman (resto)",
		stage: 4,
		head_item: 16947,
		neck_item: 19371,
		shoulder_item: 18810,
		back_item: 19430,
		chest_item: 13346,
		wrist_item: 16943,
		hand_item: 16948,
		waist_item: 19162,
		leg_item: 18875,
		foot_item: 19391,
		finger1_item: 19397,
		finger2_item: 19382,
		trinket1_item: 17064,
		trinket2_item: 19395,
		mainhand_item: 19347,
		offhand_item: 19312,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 63,
		race : null,
		faction: null,
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 4,
		head_item: 19375,
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19378,
		chest_item: 19145,
		wrist_item: 19374,
		hand_item: 19407,
		waist_item: 18809,
		leg_item: 19133,
		foot_item: 19131,
		finger1_item: 19403,
		finger2_item: 19434,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19356,
		offhand_item: 0,
		range_item: 13396,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 64,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior (fury)",
		stage: 4,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 14551,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19352,
		offhand_item: 19351,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 125,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Orc (fury)",
		stage: 4,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19143,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19363,
		offhand_item: 19362,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 126,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Human (fury)",
		stage: 4,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19143,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19352,
		offhand_item: 19351,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 65,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior (prot)",
		stage: 4,
		head_item: 16963,
		neck_item: 19383,
		shoulder_item: 16961,
		back_item: 18495,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 16964,
		waist_item: 16960,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19335,
		offhand_item: 19349,
		range_item: 19368,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 15389,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 127,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Orc (prot)",
		stage: 4,
		head_item: 16963,
		neck_item: 19383,
		shoulder_item: 16961,
		back_item: 18495,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 16964,
		waist_item: 16960,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19363,
		offhand_item: 19349,
		range_item: 19368,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 15389,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 128,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Human (prot)",
		stage: 4,
		head_item: 16963,
		neck_item: 19383,
		shoulder_item: 16961,
		back_item: 18495,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 16964,
		waist_item: 16960,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19335,
		offhand_item: 19349,
		range_item: 19368,
		head_enchant: 15389,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 15389,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 66,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 5,
		head_item: 20623,
		neck_item: 17065,
		shoulder_item: 19389,
		back_item: 19386,
		chest_item: 19405,
		wrist_item: 12966,
		hand_item: 13258,
		waist_item: 19396,
		leg_item: 20627,
		foot_item: 19381,
		finger1_item: 19376,
		finger2_item: 18879,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22846,
		shoulder_enchant: 24422,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 20012,
		leg_enchant: 22846,
		foot_enchant: 13890,
		mainhand_enchant: 23800,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 67,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-cat",
		name: "Druid (feral, cat)",
		stage: 5,
		head_item: 8345,
		neck_item: 19377,
		shoulder_item: 12927,
		back_item: 19436,
		chest_item: 19405,
		wrist_item: 19146,
		hand_item: 20259,
		waist_item: 20216,
		leg_item: 15062,
		foot_item: 19381,
		finger1_item: 19384,
		finger2_item: 19432,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19358,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 23799,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 68,
		race : null,
		faction: null,
		wowclass: "druid",		
		wowspec: "druid-resto",
		name: "Druid (resto)",
		stage: 5,
		head_item: 20628,
		neck_item: 19885,
		shoulder_item: 18810,
		back_item: 19430,
		chest_item: 13346,
		wrist_item: 16904,
		hand_item: 16899,
		waist_item: 19162,
		leg_item: 19385,
		foot_item: 16898,
		finger1_item: 19140,
		finger2_item: 19382,
		trinket1_item: 19395,
		trinket2_item: 17064,
		mainhand_item: 19360,
		offhand_item: 19312,
		range_item: 0,
		head_enchant: 24168,
		shoulder_enchant: 24420,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23802,
		hand_enchant: 0,
		leg_enchant: 24168,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 69,
		race : null,
		faction: null,
		wowclass: "hunter",
		wowspec: null,
		name: "Hunter",
		stage: 5,
		head_item: 16939,
		neck_item: 19377,
		shoulder_item: 16937,
		back_item: 17102,
		chest_item: 16942,
		wrist_item: 16935,
		hand_item: 16940,
		waist_item: 16936,
		leg_item: 16938,
		foot_item: 16941,
		finger1_item: 19325,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 19406,
		mainhand_item: 19865,
		offhand_item: 19859,
		range_item: 19361,
		head_enchant: 24162,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 20012,
		leg_enchant: 24162,
		foot_enchant: 13890,
		mainhand_enchant: 23800,
		offhand_enchant: 23800,
		range_enchant: 22779
	},
	{
		id: 70,
		race : null,
		faction: null,
		wowclass: "mage",
		wowspec: null,
		name: "Mage",
		stage: 5,
		head_item: 19375,
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19857,
		chest_item: 19682,
		wrist_item: 19374,
		hand_item: 16913,
		waist_item: 19136,
		leg_item: 16915,
		foot_item: 19438,
		finger1_item: 19403,
		finger2_item: 19147,
		trinket1_item: 19379,
		trinket2_item: 19339,
		mainhand_item: 19356,
		offhand_item: 0,
		range_item: 19130,
		head_enchant: 24164,
		shoulder_enchant: 24421,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 24164,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 71,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-holy",
		name: "Paladin (holy)",
		stage: 5,
		head_item: 20628,
		neck_item: 19885,
		shoulder_item: 18810,
		back_item: 19430,
		chest_item: 19145,
		wrist_item: 13969,
		hand_item: 20264,
		waist_item: 19162,
		leg_item: 19385,
		foot_item: 19437,
		finger1_item: 19382,
		finger2_item: 19140,
		trinket1_item: 17064,
		trinket2_item: 19395,
		mainhand_item: 19360,
		offhand_item: 19312,
		range_item: 0,
		head_enchant: 24160,
		shoulder_enchant: 24420,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 24160,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 72,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-holy",
		name: "Priest (holy)",
		stage: 5,
		head_item: 16921,
		neck_item: 19885,
		shoulder_item: 16924,
		back_item: 19430,
		chest_item: 16923,
		wrist_item: 16926,
		hand_item: 16920,
		waist_item: 16925,
		leg_item: 16922,
		foot_item: 16919,
		finger1_item: 19382,
		finger2_item: 19140,
		trinket1_item: 19395,
		trinket2_item: 17064,
		mainhand_item: 18608,
		offhand_item: 0,
		range_item: 19435,
		head_enchant: 24167,
		shoulder_enchant: 24420,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 0,
		leg_enchant: 24167,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 73,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 5,
		head_item: 19886,
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19857,
		chest_item: 19682,
		wrist_item: 19374,
		hand_item: 19407,
		waist_item: 19400,
		leg_item: 19133,
		foot_item: 19131,
		finger1_item: 19403,
		finger2_item: 19434,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19360,
		offhand_item: 19366,
		range_item: 19861,
		head_enchant: 22844,
		shoulder_enchant: 24421,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 74,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-sword",
		name: "Rogue (sword)",
		stage: 5,
		head_item: 16908,
		neck_item: 19377,
		shoulder_item: 16832,
		back_item: 19398,
		chest_item: 16905,
		wrist_item: 16911,
		hand_item: 16907,
		waist_item: 16910,
		leg_item: 16909,
		foot_item: 16906,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19352,
		offhand_item: 19351,
		range_item: 17069,
		head_enchant: 24161,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 24161,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 75,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-dagger",
		name: "Rogue (Dagger)",
		stage: 5,
		head_item: 16908,
		neck_item: 19377,
		shoulder_item: 16823,
		back_item: 19398,
		chest_item: 16905,
		wrist_item: 16911,
		hand_item: 18823,
		waist_item: 16910,
		leg_item: 16909,
		foot_item: 19381,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 18816,
		offhand_item: 18805,
		range_item: 17069,
		head_enchant: 24161,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 24161,
		foot_enchant: 13890,
		mainhand_enchant: 20031,
		offhand_enchant: 23800,
		range_enchant: 0
	},
	{
		id: 76,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-elemental",
		name: "Shaman (elemental)",
		stage: 5,
		head_item: 19375,
		neck_item: 18814,
		shoulder_item: 18829,
		back_item: 19857,
		chest_item: 19682,
		wrist_item: 19374,
		hand_item: 16839,
		waist_item: 19400,
		leg_item: 19683,
		foot_item: 19684,
		finger1_item: 19397,
		finger2_item: 19403,
		trinket1_item: 19379,
		trinket2_item: 19344,
		mainhand_item: 19360,
		offhand_item: 19366,
		range_item: 0,
		head_enchant: 24163,
		shoulder_enchant: 24421,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 0,
		leg_enchant: 24163,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 77,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-resto",
		name: "Shaman (resto)",
		stage: 5,
		head_item: 16947,
		neck_item: 19371,
		shoulder_item: 18810,
		back_item: 19430,
		chest_item: 13346,
		wrist_item: 16943,
		hand_item: 16948,
		waist_item: 19162,
		leg_item: 18875,
		foot_item: 19391,
		finger1_item: 19397,
		finger2_item: 19382,
		trinket1_item: 17064,
		trinket2_item: 19395,
		mainhand_item: 19347,
		offhand_item: 19312,
		range_item: 0,
		head_enchant: 24163,
		shoulder_enchant: 24420,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 0,
		leg_enchant: 24163,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 78,
		race : null,
		faction: null,
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 5,
		head_item: 19375,
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19857,
		chest_item: 19682,
		wrist_item: 19374,
		hand_item: 19407,
		waist_item: 18809,
		leg_item: 19683,
		foot_item: 19684,
		finger1_item: 19403,
		finger2_item: 19434,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19356,
		offhand_item: 0,
		range_item: 19861,
		head_enchant: 24165,
		shoulder_enchant: 24421,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 24165,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 79,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior (fury)",
		stage: 5,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 14551,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19352,
		offhand_item: 19351,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 129,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Orc (fury)",
		stage: 5,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19143,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19363,
		offhand_item: 19362,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 130,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Human (fury)",
		stage: 5,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 18541,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19143,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 11815,
		mainhand_item: 19352,
		offhand_item: 19351,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 80,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior (prot)",
		stage: 5,
		head_item: 16963,
		neck_item: 19383,
		shoulder_item: 16961,
		back_item: 19888,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 16964,
		waist_item: 16960,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 19873,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19363,
		offhand_item: 19349,
		range_item: 19368,
		head_enchant: 24149,
		shoulder_enchant: 24422,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 24149,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 131,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Orc (prot)",
		stage: 5,
		head_item: 16963,
		neck_item: 19383,
		shoulder_item: 16961,
		back_item: 19888,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 16964,
		waist_item: 16960,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 19873,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19363,
		offhand_item: 19349,
		range_item: 19368,
		head_enchant: 24149,
		shoulder_enchant: 24422,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 24149,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 132,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Human (prot)",
		stage: 5,
		head_item: 16963,
		neck_item: 19383,
		shoulder_item: 16961,
		back_item: 19888,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 16964,
		waist_item: 16960,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 19873,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19352,
		offhand_item: 19349,
		range_item: 19368,
		head_enchant: 24149,
		shoulder_enchant: 24422,
		back_enchant: 20015,
		chest_enchant: 20026,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 24149,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 81,
		race : null,
		faction: "horde",
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 6,
		head_item: 21693,
		neck_item: 22732,
		shoulder_item: 20194,
		back_item: 19386,
		chest_item: 19405,
		wrist_item: 21602,
		hand_item: 21605,
		waist_item: 21675,
		leg_item: 22740,
		foot_item: 19381,
		finger1_item: 21601,
		finger2_item: 18879,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 23198,
		head_enchant: 22846,
		shoulder_enchant: 24422,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 22846,
		foot_enchant: 13890,
		mainhand_enchant: 27837,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 82,
		race : null,
		faction: "alliance",
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 6,
		head_item: 21693,
		neck_item: 22732,
		shoulder_item: 20059,
		back_item: 19386,
		chest_item: 19405,
		wrist_item: 21602,
		hand_item: 21605,
		waist_item: 21675,
		leg_item: 22749,
		foot_item: 19381,
		finger1_item: 21601,
		finger2_item: 18879,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 23198,
		head_enchant: 22846,
		shoulder_enchant: 24422,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 22846,
		foot_enchant: 13890,
		mainhand_enchant: 27837,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 83,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-cat",
		name: "Druid (feral, cat)",
		stage: 6,
		head_item: 8345,
		neck_item: 19377,
		shoulder_item: 21665,
		back_item: 21701,
		chest_item: 21680,
		wrist_item: 21602,
		hand_item: 21672,
		waist_item: 21586,
		leg_item: 20665,
		foot_item: 21493,
		finger1_item: 21205,
		finger2_item: 17063,
		trinket1_item: 19406,
		trinket2_item: 13965,
		mainhand_item: 21268,
		offhand_item: 13385,
		range_item: 22397,
		head_enchant: 15402,
		shoulder_enchant: 24422,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 25080,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 23799,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 84,
		race : null,
		faction: null,
		wowclass: "druid",		
		wowspec: "druid-resto",
		name: "Druid (resto)",
		stage: 6,
		head_item: 20628,
		neck_item: 21712,
		shoulder_item: 18810,
		back_item: 21583,
		chest_item: 21663,
		wrist_item: 21604,
		hand_item: 21617,
		waist_item: 21582,
		leg_item: 19385,
		foot_item: 19437,
		finger1_item: 21620,
		finger2_item: 19382,
		trinket1_item: 19395,
		trinket2_item: 17064,
		mainhand_item: 21839,
		offhand_item: 21666,
		range_item: 22399,
		head_enchant: 24168,
		shoulder_enchant: 24420,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23802,
		hand_enchant: 25079,
		leg_enchant: 24168,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 85,
		race : null,
		faction: null,
		wowclass: "hunter",
		wowspec: null,
		name: "Hunter",
		stage: 6,
		head_item: 16939,
		neck_item: 19377,
		shoulder_item: 16937,
		back_item: 21710,
		chest_item: 16942,
		wrist_item: 16935,
		hand_item: 16940,
		waist_item: 16936,
		leg_item: 16938,
		foot_item: 16941,
		finger1_item: 19325,
		finger2_item: 17063,
		trinket1_item: 21670,
		trinket2_item: 23570,
		mainhand_item: 21673,
		offhand_item: 19859,
		range_item: 19361,
		head_enchant: 24162,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25080,
		leg_enchant: 24162,
		foot_enchant: 13890,
		mainhand_enchant: 23800,
		offhand_enchant: 23800,
		range_enchant: 22779
	},
	{
		id: 86,
		race : null,
		faction: null,
		wowclass: "mage",
		wowspec: null,
		name: "Mage",
		stage: 6,
		head_item: 19375,
		neck_item: 21608,
		shoulder_item: 19370,
		back_item: 22731,
		chest_item: 19145,
		wrist_item: 21186,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 21461,
		foot_item: 21344,
		finger1_item: 21836,
		finger2_item: 21709,
		trinket1_item: 19379,
		trinket2_item: 19339,
		mainhand_item: 21622,
		offhand_item: 21597,
		range_item: 21603,
		head_enchant: 24164,
		shoulder_enchant: 24421,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25078,
		leg_enchant: 24164,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 87,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-holy",
		name: "Paladin (holy)",
		stage: 6,
		head_item: 20628,
		neck_item: 21712,
		shoulder_item: 18810,
		back_item: 21583,
		chest_item: 21663,
		wrist_item: 21604,
		hand_item: 20264,
		waist_item: 21582,
		leg_item: 21667,
		foot_item: 19437,
		finger1_item: 19382,
		finger2_item: 21620,
		trinket1_item: 17064,
		trinket2_item: 19395,
		mainhand_item: 21839,
		offhand_item: 21666,
		range_item: 22402,
		head_enchant: 24160,
		shoulder_enchant: 24420,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25079,
		leg_enchant: 24160,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 88,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-holy",
		name: "Priest (holy)",
		stage: 6,
		head_item: 21615,
		neck_item: 21712,
		shoulder_item: 16924,
		back_item: 21583,
		chest_item: 21663,
		wrist_item: 16926,
		hand_item: 21619,
		waist_item: 21582,
		leg_item: 16922,
		foot_item: 19437,
		finger1_item: 19382,
		finger2_item: 21620,
		trinket1_item: 19395,
		trinket2_item: 17064,
		mainhand_item: 21839,
		offhand_item: 21666,
		range_item: 21801,
		head_enchant: 24167,
		shoulder_enchant: 24420,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 25079,
		leg_enchant: 24167,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 89,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 6,
		head_item: 21348,
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 22731,
		chest_item: 21351,
		wrist_item: 21611,
		hand_item: 21585,
		waist_item: 19400,
		leg_item: 19133,
		foot_item: 19131,
		finger1_item: 21709,
		finger2_item: 21210,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19360,
		offhand_item: 19366,
		range_item: 21603,
		head_enchant: 22844,
		shoulder_enchant: 24421,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 25073,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 90,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-sword",
		name: "Rogue (sword)",
		stage: 6,
		head_item: 21360,
		neck_item: 19377,
		shoulder_item: 21361,
		back_item: 21701,
		chest_item: 21364,
		wrist_item: 21602,
		hand_item: 21672,
		waist_item: 21586,
		leg_item: 21362,
		foot_item: 21359,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 23570,
		mainhand_item: 21650,
		offhand_item: 19351,
		range_item: 17069,
		head_enchant: 24161,
		shoulder_enchant: 24422,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 25080,
		leg_enchant: 24161,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 91,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-dagger",
		name: "Rogue (Dagger)",
		stage: 6,
		head_item: 21360,
		neck_item: 19377,
		shoulder_item: 21361,
		back_item: 21701,
		chest_item: 21364,
		wrist_item: 21602,
		hand_item: 18823,
		waist_item: 21586,
		leg_item: 21362,
		foot_item: 21359,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 23570,
		mainhand_item: 21126,
		offhand_item: 21244,
		range_item: 17069,
		head_enchant: 24161,
		shoulder_enchant: 24422,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 25080,
		leg_enchant: 24161,
		foot_enchant: 13890,
		mainhand_enchant: 20031,
		offhand_enchant: 23800,
		range_enchant: 0
	},
	{
		id: 92,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-elemental",
		name: "Shaman (elemental)",
		stage: 6,
		head_item: 19375,
		neck_item: 21608,
		shoulder_item: 21376,
		back_item: 22731,
		chest_item: 21671,
		wrist_item: 21464,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 21375,
		foot_item: 21373,
		finger1_item: 21707,
		finger2_item: 21709,
		trinket1_item: 19379,
		trinket2_item: 19344,
		mainhand_item: 19360,
		offhand_item: 19366,
		range_item: 23199,
		head_enchant: 24163,
		shoulder_enchant: 24421,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 25079,
		leg_enchant: 24163,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 93,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-resto",
		name: "Shaman (resto)",
		stage: 6,
		head_item: 21372,
		neck_item: 21712,
		shoulder_item: 21376,
		back_item: 21583,
		chest_item: 21374,
		wrist_item: 16943,
		hand_item: 16948,
		waist_item: 16944,
		leg_item: 21375,
		foot_item: 21373,
		finger1_item: 21620,
		finger2_item: 21681,
		trinket1_item: 17064,
		trinket2_item: 19395,
		mainhand_item: 21839,
		offhand_item: 21610,
		range_item: 22396,
		head_enchant: 24163,
		shoulder_enchant: 24420,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 25079,
		leg_enchant: 24163,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 94,
		race : null,
		faction: null,
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 6,
		head_item: 21337,
		neck_item: 21608,
		shoulder_item: 21335,
		back_item: 22731,
		chest_item: 19682,
		wrist_item: 21186,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 19683,
		foot_item: 19684,
		finger1_item: 21417,
		finger2_item: 21709,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 21622,
		offhand_item: 21597,
		range_item: 21603,
		head_enchant: 24165,
		shoulder_enchant: 24421,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25073,
		leg_enchant: 24165,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 95,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior (fury)",
		stage: 6,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 21330,
		back_item: 18541,
		chest_item: 21814,
		wrist_item: 21618,
		hand_item: 14551,
		waist_item: 19137,
		leg_item: 22385,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 21670,
		mainhand_item: 21650,
		offhand_item: 21650,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 24422,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 133,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Orc (fury)",
		stage: 6,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 21330,
		back_item: 18541,
		chest_item: 21814,
		wrist_item: 21618,
		hand_item: 21581,
		waist_item: 19137,
		leg_item: 22385,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 21670,
		mainhand_item: 19363,
		offhand_item: 21242,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 24422,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 134,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior Human (fury)",
		stage: 6,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 21330,
		back_item: 18541,
		chest_item: 21814,
		wrist_item: 21618,
		hand_item: 21581,
		waist_item: 19137,
		leg_item: 22385,
		foot_item: 19387,
		finger1_item: 17063,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 21670,
		mainhand_item: 21650,
		offhand_item: 21650,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 24422,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 96,
		race : "other-warrior",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior (prot)",
		stage: 6,
		head_item: 16963,
		neck_item: 22732,
		shoulder_item: 21639,
		back_item: 19888,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 21674,
		waist_item: 21598,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 21601,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19363,
		offhand_item: 21269,
		range_item: 19368,
		head_enchant: 24149,
		shoulder_enchant: 24422,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 24149,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 135,
		race : "orc",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Orc (prot)",
		stage: 6,
		head_item: 16963,
		neck_item: 22732,
		shoulder_item: 21639,
		back_item: 19888,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 21674,
		waist_item: 21598,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 21601,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19363,
		offhand_item: 21269,
		range_item: 19368,
		head_enchant: 24149,
		shoulder_enchant: 24422,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 24149,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 136,
		race : "human",
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior Human (prot)",
		stage: 6,
		head_item: 16963,
		neck_item: 22732,
		shoulder_item: 21639,
		back_item: 19888,
		chest_item: 16966,
		wrist_item: 16959,
		hand_item: 21674,
		waist_item: 21598,
		leg_item: 16962,
		foot_item: 16965,
		finger1_item: 21601,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 19406,
		mainhand_item: 21650,
		offhand_item: 21269,
		range_item: 19368,
		head_enchant: 24149,
		shoulder_enchant: 24422,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 24149,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 97,
		race : null,
		faction: "horde",
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 7,
		head_item: 21693,
		neck_item: 22732,
		shoulder_item: 20194,
		back_item: 22938,
		chest_item: 23226,
		wrist_item: 22663,
		hand_item: 21605,
		waist_item: 21675,
		leg_item: 22740,
		foot_item: 19381,
		finger1_item: 21601,
		finger2_item: 23018,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 23198,
		head_enchant: 22846,
		shoulder_enchant: 29480,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 22846,
		foot_enchant: 13890,
		mainhand_enchant: 27837,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 98,
		race : null,
		faction: "alliance",
		wowclass: "druid",
		wowspec: "druid-feral-bear",
		name: "Druid (feral, bear)",
		stage: 7,
		head_item: 21693,
		neck_item: 22732,
		shoulder_item: 20059,
		back_item: 22938,
		chest_item: 23226,
		wrist_item: 22663,
		hand_item: 21605,
		waist_item: 21675,
		leg_item: 22749,
		foot_item: 19381,
		finger1_item: 21601,
		finger2_item: 23018,
		trinket1_item: 13966,
		trinket2_item: 11811,
		mainhand_item: 943,
		offhand_item: 0,
		range_item: 23198,
		head_enchant: 22846,
		shoulder_enchant: 29480,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 22846,
		foot_enchant: 13890,
		mainhand_enchant: 27837,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 99,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-feral-cat",
		name: "Druid (feral, cat)",
		stage: 7,
		head_item: 8345,
		neck_item: 19377,
		shoulder_item: 21665,
		back_item: 21710,
		chest_item: 23226,
		wrist_item: 21602,
		hand_item: 21672,
		waist_item: 21586,
		leg_item: 23071,
		foot_item: 21493,
		finger1_item: 23038,
		finger2_item: 19432,
		trinket1_item: 19406,
		trinket2_item: 23041,
		mainhand_item: 22988,
		offhand_item: 13385,
		range_item: 22397,
		head_enchant: 15402,
		shoulder_enchant: 29483,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 25080,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 23799,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 100,
		race : null,
		faction: null,
		wowclass: "druid",		
		wowspec: "druid-resto",
		name: "Druid (resto)",
		stage: 7,
		head_item: 20628,
		neck_item: 21712,
		shoulder_item: 22491,
		back_item: 22960,
		chest_item: 22488,
		wrist_item: 21604,
		hand_item: 22493,
		waist_item: 21582,
		leg_item: 22489,
		foot_item: 22492,
		finger1_item: 21620,
		finger2_item: 22939,
		trinket1_item: 23047,
		trinket2_item: 23027,
		mainhand_item: 23056,
		offhand_item: 23048,
		range_item: 22399,
		head_enchant: 24168,
		shoulder_enchant: 29475,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23802,
		hand_enchant: 25079,
		leg_enchant: 24168,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 101,
		race : null,
		faction: null,
		wowclass: "hunter",
		wowspec: null,
		name: "Hunter",
		stage: 7,
		head_item: 22438,
		neck_item: 23053,
		shoulder_item: 22439,
		back_item: 23045,
		chest_item: 22436,
		wrist_item: 22443,
		hand_item: 16571,
		waist_item: 22442,
		leg_item: 22437,
		foot_item: 22440,
		finger1_item: 23067,
		finger2_item: 22961,
		trinket1_item: 21670,
		trinket2_item: 23041,
		mainhand_item: 22816,
		offhand_item: 22802,
		range_item: 22812,
		head_enchant: 24162,
		shoulder_enchant: 29483,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25080,
		leg_enchant: 24162,
		foot_enchant: 13890,
		mainhand_enchant: 23800,
		offhand_enchant: 23800,
		range_enchant: 22779
	},
	{
		id: 102,
		race : null,
		faction: null,
		wowclass: "mage",
		wowspec: null,
		name: "Mage",
		stage: 7,
		head_item: 22498,
		neck_item: 23057,
		shoulder_item: 22983,
		back_item: 23050,
		chest_item: 22496,
		wrist_item: 23021,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 23070,
		foot_item: 22500,
		finger1_item: 23237,
		finger2_item: 23062,
		trinket1_item: 19379,
		trinket2_item: 23046,
		mainhand_item: 22807,
		offhand_item: 23049,
		range_item: 22821,
		head_enchant: 24164,
		shoulder_enchant: 29467,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25078,
		leg_enchant: 24164,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 103,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-holy",
		name: "Paladin (holy)",
		stage: 7,
		head_item: 20628,
		neck_item: 23057,
		shoulder_item: 22429,
		back_item: 23050,
		chest_item: 22425,
		wrist_item: 21604,
		hand_item: 20264,
		waist_item: 21582,
		leg_item: 22427,
		foot_item: 22430,
		finger1_item: 19382,
		finger2_item: 23066,
		trinket1_item: 23047,
		trinket2_item: 19395,
		mainhand_item: 23056,
		offhand_item: 23075,
		range_item: 23006,
		head_enchant: 24160,
		shoulder_enchant: 29475,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25079,
		leg_enchant: 24160,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 104,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-holy",
		name: "Priest (holy)",
		stage: 7,
		head_item: 22514,
		neck_item: 21712,
		shoulder_item: 22515,
		back_item: 22960,
		chest_item: 22512,
		wrist_item: 21604,
		hand_item: 22517,
		waist_item: 21582,
		leg_item: 22513,
		foot_item: 22516,
		finger1_item: 23061,
		finger2_item: 22939,
		trinket1_item: 23027,
		trinket2_item: 23047,
		mainhand_item: 23056,
		offhand_item: 23048,
		range_item: 23009,
		head_enchant: 24167,
		shoulder_enchant: 29475,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20009,
		hand_enchant: 25079,
		leg_enchant: 24167,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 105,
		race : null,
		faction: null,
		wowclass: "priest",
		wowspec: "priest-shadow",
		name: "Priest (shadow)",
		stage: 7,
		head_item: 23035,
		neck_item: 18814,
		shoulder_item: 22983,
		back_item: 22731,
		chest_item: 23220,
		wrist_item: 21611,
		hand_item: 21585,
		waist_item: 19400,
		leg_item: 19133,
		foot_item: 19131,
		finger1_item: 21709,
		finger2_item: 21210,
		trinket1_item: 19379,
		trinket2_item: 23046,
		mainhand_item: 22988,
		offhand_item: 23049,
		range_item: 21603,
		head_enchant: 22844,
		shoulder_enchant: 29467,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 25073,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 106,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-sword",
		name: "Rogue (sword)",
		stage: 7,
		head_item: 22478,
		neck_item: 19377,
		shoulder_item: 22479,
		back_item: 23045,
		chest_item: 22476,
		wrist_item: 22483,
		hand_item: 22481,
		waist_item: 21586,
		leg_item: 22477,
		foot_item: 22480,
		finger1_item: 23060,
		finger2_item: 23038,
		trinket1_item: 23041,
		trinket2_item: 22954,
		mainhand_item: 23054,
		offhand_item: 23577,
		range_item: 22812,
		head_enchant: 24161,
		shoulder_enchant: 29483,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 25080,
		leg_enchant: 24161,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 107,
		race : null,
		faction: null,
		wowclass: "rogue",
		wowspec: "rogue-dagger",
		name: "Rogue (Dagger)",
		stage: 7,
		head_item: 22478,
		neck_item: 19377,
		shoulder_item: 22479,
		back_item: 23045,
		chest_item: 22476,
		wrist_item: 22483,
		hand_item: 22481,
		waist_item: 21586,
		leg_item: 22477,
		foot_item: 22480,
		finger1_item: 23060,
		finger2_item: 23038,
		trinket1_item: 23041,
		trinket2_item: 22954,
		mainhand_item: 22802,
		offhand_item: 21126,
		range_item: 22812,
		head_enchant: 24161,
		shoulder_enchant: 29483,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 25080,
		leg_enchant: 24161,
		foot_enchant: 13890,
		mainhand_enchant: 20031,
		offhand_enchant: 23800,
		range_enchant: 0
	},
	{
		id: 108,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-elemental",
		name: "Shaman (elemental)",
		stage: 7,
		head_item: 19375,
		neck_item: 22943,
		shoulder_item: 21376,
		back_item: 23050,
		chest_item: 21671,
		wrist_item: 21464,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 21375,
		foot_item: 21373,
		finger1_item: 21707,
		finger2_item: 21709,
		trinket1_item: 19379,
		trinket2_item: 23046,
		mainhand_item: 22988,
		offhand_item: 23049,
		range_item: 23199,
		head_enchant: 24163,
		shoulder_enchant: 29467,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 25079,
		leg_enchant: 24163,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 109,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-resto",
		name: "Shaman (resto)",
		stage: 7,
		head_item: 22466,
		neck_item: 21712,
		shoulder_item: 22467,
		back_item: 21583,
		chest_item: 22464,
		wrist_item: 22471,
		hand_item: 21619,
		waist_item: 22470,
		leg_item: 22465,
		foot_item: 22468,
		finger1_item: 21620,
		finger2_item: 23065,
		trinket1_item: 23027,
		trinket2_item: 19395,
		mainhand_item: 23056,
		offhand_item: 22819,
		range_item: 22396,
		head_enchant: 24163,
		shoulder_enchant: 29475,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 23801,
		hand_enchant: 25079,
		leg_enchant: 24163,
		foot_enchant: 13890,
		mainhand_enchant: 22750,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 110,
		race : null,
		faction: null,
		wowclass: "warlock",
		wowspec: null,
		name: "Warlock",
		stage: 7,
		head_item: 22506,
		neck_item: 21608,
		shoulder_item: 22507,
		back_item: 23050,
		chest_item: 22504,
		wrist_item: 21186,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 23070,
		foot_item: 22508,
		finger1_item: 23031,
		finger2_item: 21709,
		trinket1_item: 19379,
		trinket2_item: 23046,
		mainhand_item: 22807,
		offhand_item: 23049,
		range_item: 22820,
		head_enchant: 24165,
		shoulder_enchant: 29467,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25073,
		leg_enchant: 24165,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 111,
		race : null,
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-fury",
		name: "Warrior (fury)",
		stage: 7,
		head_item: 12640,
		neck_item: 18404,
		shoulder_item: 21330,
		back_item: 23045,
		chest_item: 23000,
		wrist_item: 22936,
		hand_item: 21581,
		waist_item: 19137,
		leg_item: 22385,
		foot_item: 19387,
		finger1_item: 23038,
		finger2_item: 19384,
		trinket1_item: 19406,
		trinket2_item: 21670,
		mainhand_item: 23054,
		offhand_item: 23577,
		range_item: 17069,
		head_enchant: 15397,
		shoulder_enchant: 29483,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20013,
		leg_enchant: 15397,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20034,
		range_enchant: 0
	},
	{
		id: 112,
		race : null,
		faction: null,
		wowclass: "warrior",
		wowspec: "warrior-prot",
		name: "Warrior (prot)",
		stage: 7,
		head_item: 22418,
		neck_item: 22732,
		shoulder_item: 22419,
		back_item: 22938,
		chest_item: 22416,
		wrist_item: 22423,
		hand_item: 22421,
		waist_item: 22422,
		leg_item: 22417,
		foot_item: 22420,
		finger1_item: 21601,
		finger2_item: 23059,
		trinket1_item: 19431,
		trinket2_item: 19406,
		mainhand_item: 23577,
		offhand_item: 23043,
		range_item: 19368,
		head_enchant: 24149,
		shoulder_enchant: 29480,
		back_enchant: 25086,
		chest_enchant: 20026,
		wrist_enchant: 20011,
		hand_enchant: 25072,
		leg_enchant: 24149,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 137,
		race : null,
		faction: "horde",
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 1,
		head_item: 10504,	
		neck_item: 12103,
		shoulder_item: 13013,
		back_item: 13386,
		chest_item: 11924,
		wrist_item: 11766,
		hand_item: 13258,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 11822,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 13964,
		offhand_item: 10796,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 138,
		race : null,
		faction: "alliance",
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 1,
		head_item: 10504,	
		neck_item: 12103,
		shoulder_item: 13013,
		back_item: 13386,
		chest_item: 11924,
		wrist_item: 11766,
		hand_item: 13258,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 11822,
		finger1_item: 12543,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 13964,
		offhand_item: 10796,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 139,
		race : null,
		faction: "horde",
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 2,
		head_item: 10504,	
		neck_item: 12103,
		shoulder_item: 13013,
		back_item: 13386,
		chest_item: 18385,
		wrist_item: 11766,
		hand_item: 13258,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 11822,
		finger1_item: 12545,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 18534,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 140,
		race : null,
		faction: "alliance",
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 2,
		head_item: 10504,	
		neck_item: 12103,
		shoulder_item: 13013,
		back_item: 13386,
		chest_item: 18385,
		wrist_item: 11766,
		hand_item: 13258,
		waist_item: 11662,
		leg_item: 13170,
		foot_item: 11822,
		finger1_item: 12543,
		finger2_item: 13001,
		trinket1_item: 12930,
		trinket2_item: 13968,
		mainhand_item: 18534,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 0,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 141,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 3,
		head_item: 10504,	
		neck_item: 18814,
		shoulder_item: 16836,
		back_item: 13386,
		chest_item: 19145,
		wrist_item: 11766,
		hand_item: 13258,
		waist_item: 19136,
		leg_item: 13170,
		foot_item: 11822,
		finger1_item: 19147,
		finger2_item: 19147,
		trinket1_item: 12930,
		trinket2_item: 18820,
		mainhand_item: 18842,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 142,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 4,
		head_item: 19375,	
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19378,
		chest_item: 19145,
		wrist_item: 19374,
		hand_item: 13258,
		waist_item: 19400,
		leg_item: 13170,
		foot_item: 11822,
		finger1_item: 19403,
		finger2_item: 19147,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19360,
		offhand_item: 19308,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 22599,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 143,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 5,
		head_item: 19375,	
		neck_item: 18814,
		shoulder_item: 19370,
		back_item: 19378,
		chest_item: 19682,
		wrist_item: 19374,
		hand_item: 13258,
		waist_item: 19400,
		leg_item: 19683,
		foot_item: 19684,
		finger1_item: 19403,
		finger2_item: 19147,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19360,
		offhand_item: 19308,
		range_item: 0,
		head_enchant: 22844,
		shoulder_enchant: 24421,
		back_enchant: 20014,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 0,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 144,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 6,
		head_item: 19375,	
		neck_item: 21608,
		shoulder_item: 19370,
		back_item: 22731,
		chest_item: 19682,
		wrist_item: 21186,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 19683,
		foot_item: 19684,
		finger1_item: 19403,
		finger2_item: 21709,
		trinket1_item: 19379,
		trinket2_item: 18820,
		mainhand_item: 19360,
		offhand_item: 21597,
		range_item: 23197,
		head_enchant: 22844,
		shoulder_enchant: 24421,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25079,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 145,
		race : null,
		faction: null,
		wowclass: "druid",
		wowspec: "druid-balance",
		name: "Druid (balance)",
		stage: 7,
		head_item: 19375,	
		neck_item: 23057,
		shoulder_item: 22983,
		back_item: 23050,
		chest_item: 19682,
		wrist_item: 23021,
		hand_item: 21585,
		waist_item: 22730,
		leg_item: 19683,
		foot_item: 19684,
		finger1_item: 23025,
		finger2_item: 21709,
		trinket1_item: 19379,
		trinket2_item: 23046,
		mainhand_item: 22988,
		offhand_item: 23049,
		range_item: 23197,
		head_enchant: 22844,
		shoulder_enchant: 29467,
		back_enchant: 25084,
		chest_enchant: 20025,
		wrist_enchant: 20008,
		hand_enchant: 25079,
		leg_enchant: 22844,
		foot_enchant: 13890,
		mainhand_enchant: 22749,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 146,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-retribution",
		name: "Paladin (retribution)",
		stage: 1,
		head_item: 13404,	
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 13957,
		waist_item: 13959,
		leg_item: 14554,
		foot_item: 14616,
		finger1_item: 13098,
		finger2_item: 12548,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12784,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 20023,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 147,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-retribution",
		name: "Paladin (retribution)",
		stage: 2,
		head_item: 13404,	
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 12936,
		hand_item: 13957,
		waist_item: 13959,
		leg_item: 14554,
		foot_item: 14616,
		finger1_item: 13098,
		finger2_item: 12548,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12784,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 20023,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 148,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-retribution",
		name: "Paladin (retribution)",
		stage: 3,
		head_item: 12640,	
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19143,
		waist_item: 19137,
		leg_item: 14554,
		foot_item: 14616,
		finger1_item: 13098,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17076,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 20023,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 149,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-retribution",
		name: "Paladin (retribution)",
		stage: 4,
		head_item: 12640,	
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 19436,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19143,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 19432,
		finger2_item: 19384,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 19334,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 20023,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 150,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-retribution",
		name: "Paladin (retribution)",
		stage: 5,
		head_item: 12640,	
		neck_item: 18404,
		shoulder_item: 19394,
		back_item: 19436,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19143,
		waist_item: 19137,
		leg_item: 19402,
		foot_item: 19387,
		finger1_item: 19432,
		finger2_item: 19384,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 19334,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 20023,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 151,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-retribution",
		name: "Paladin (retribution)",
		stage: 6,
		head_item: 21387,	
		neck_item: 18404,
		shoulder_item: 21391,
		back_item: 21701,
		chest_item: 21389,
		wrist_item: 21618,
		hand_item: 21623,
		waist_item: 21463,
		leg_item: 21390,
		foot_item: 21388,
		finger1_item: 17063,
		finger2_item: 21205,
		trinket1_item: 22321,
		trinket2_item: 19289,
		mainhand_item: 21134,
		offhand_item: 0,
		range_item: 23203,
		head_enchant: 15402,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 20023,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 152,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-retribution",
		name: "Paladin (retribution)",
		stage: 7,
		head_item: 21387,	
		neck_item: 18404,
		shoulder_item: 21391,
		back_item: 23045,
		chest_item: 21389,
		wrist_item: 22936,
		hand_item: 21623,
		waist_item: 23219,
		leg_item: 21390,
		foot_item: 21388,
		finger1_item: 23038,
		finger2_item: 21205,
		trinket1_item: 22321,
		trinket2_item: 19289,
		mainhand_item: 22691,
		offhand_item: 0,
		range_item: 23203,
		head_enchant: 15402,
		shoulder_enchant: 29467,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 20023,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 153,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-protection",
		name: "Paladin (protection)",
		stage: 1,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 13397,
		chest_item: 14624,
		wrist_item: 12550,
		hand_item: 14622,
		waist_item: 14620,
		leg_item: 14623,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 11784,
		offhand_item: 12602,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20028,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 20020,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 154,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-protection",
		name: "Paladin (protection)",
		stage: 2,
		head_item: 12952,
		neck_item: 13091,
		shoulder_item: 14552,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 18754,
		hand_item: 14622,
		waist_item: 14620,
		leg_item: 14623,
		foot_item: 14621,
		finger1_item: 11669,
		finger2_item: 10795,
		trinket1_item: 11810,
		trinket2_item: 10779,
		mainhand_item: 18396,
		offhand_item: 12602,
		range_item: 0,
		head_enchant: 15340,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20028,
		wrist_enchant: 13931,
		hand_enchant: 20012,
		leg_enchant: 15340,
		foot_enchant: 20020,
		mainhand_enchant: 20034,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 155,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-protection",
		name: "Paladin (protection)",
		stage: 3,
		head_item: 12952,
		neck_item: 17065,
		shoulder_item: 14552,
		back_item: 18495,
		chest_item: 14624,
		wrist_item: 18754,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 14623,
		foot_item: 18806,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 11810,
		trinket2_item: 18406,
		mainhand_item: 17103,
		offhand_item: 17066,
		range_item: 0,
		head_enchant: 15340,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 22725,
		foot_enchant: 22725,
		mainhand_enchant: 22749,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 156,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-protection",
		name: "Paladin (protection)",
		stage: 4,
		head_item: 12620,
		neck_item: 19383,
		shoulder_item: 14552,
		back_item: 18495,
		chest_item: 12618,
		wrist_item: 18754,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 14623,
		foot_item: 18806,
		finger1_item: 11669,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19360,
		offhand_item: 19349,
		range_item: 0,
		head_enchant: 15340,
		shoulder_enchant: 22599,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 22725,
		foot_enchant: 22725,
		mainhand_enchant: 22749,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 157,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-protection",
		name: "Paladin (protection)",
		stage: 5,
		head_item: 16955,
		neck_item: 19383,
		shoulder_item: 14552,
		back_item: 19888,
		chest_item: 12618,
		wrist_item: 16951,
		hand_item: 13072,
		waist_item: 14620,
		leg_item: 19855,
		foot_item: 18806,
		finger1_item: 19912,
		finger2_item: 18879,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 19360,
		offhand_item: 19349,
		range_item: 0,
		head_enchant: 24160,
		shoulder_enchant: 24421,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 24160,
		foot_enchant: 22725,
		mainhand_enchant: 22749,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 158,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-protection",
		name: "Paladin (protection)",
		stage: 6,
		head_item: 21387,
		neck_item: 22732,
		shoulder_item: 21639,
		back_item: 19888,
		chest_item: 21389,
		wrist_item: 18754,
		hand_item: 21674,
		waist_item: 21598,
		leg_item: 19855,
		foot_item: 21706,
		finger1_item: 21200,
		finger2_item: 21601,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 21622,
		offhand_item: 21269,
		range_item: 22401,
		head_enchant: 24160,
		shoulder_enchant: 24421,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 24160,
		foot_enchant: 22725,
		mainhand_enchant: 22749,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 159,
		race : null,
		faction: null,
		wowclass: "paladin",
		wowspec: "paladin-protection",
		name: "Paladin (protection)",
		stage: 7,
		head_item: 21387,
		neck_item: 22732,
		shoulder_item: 21639,
		back_item: 22938,
		chest_item: 21389,
		wrist_item: 18754,
		hand_item: 21674,
		waist_item: 21598,
		leg_item: 19855,
		foot_item: 21706,
		finger1_item: 21200,
		finger2_item: 21601,
		trinket1_item: 19431,
		trinket2_item: 18406,
		mainhand_item: 22988,
		offhand_item: 22818,
		range_item: 22401,
		head_enchant: 24160,
		shoulder_enchant: 29467,
		back_enchant: 20015,
		chest_enchant: 22725,
		wrist_enchant: 13931,
		hand_enchant: 22725,
		leg_enchant: 24160,
		foot_enchant: 22725,
		mainhand_enchant: 22749,
		offhand_enchant: 20017,
		range_enchant: 0
	},
	{
		id: 160,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-enhancement",
		name: "Shaman (enhancement)",
		stage: 1,
		head_item: 12587,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 13211,
		hand_item: 13957,
		waist_item: 22232,
		leg_item: 15062,
		foot_item: 14616,
		finger1_item: 13098,
		finger2_item: 17713,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12784,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 0,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 0,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 161,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-enhancement",
		name: "Shaman (enhancement)",
		stage: 2,
		head_item: 12587,
		neck_item: 15411,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 13211,
		hand_item: 13957,
		waist_item: 22232,
		leg_item: 15062,
		foot_item: 14616,
		finger1_item: 13098,
		finger2_item: 18500,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 12784,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 162,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-enhancement",
		name: "Shaman (enhancement)",
		stage: 3,
		head_item: 18817,
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 13340,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 13957,
		waist_item: 18393,
		leg_item: 15062,
		foot_item: 14616,
		finger1_item: 17063,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17104,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 163,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-enhancement",
		name: "Shaman (enhancement)",
		stage: 4,
		head_item: 18817,
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 19436,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19157,
		waist_item: 19380,
		leg_item: 15062,
		foot_item: 19381,
		finger1_item: 19384,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17104,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 22599,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 164,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-enhancement",
		name: "Shaman (enhancement)",
		stage: 5,
		head_item: 18817,
		neck_item: 18404,
		shoulder_item: 12927,
		back_item: 19436,
		chest_item: 11726,
		wrist_item: 19146,
		hand_item: 19157,
		waist_item: 19380,
		leg_item: 15062,
		foot_item: 19381,
		finger1_item: 19384,
		finger2_item: 18821,
		trinket1_item: 13965,
		trinket2_item: 11815,
		mainhand_item: 17104,
		offhand_item: 0,
		range_item: 0,
		head_enchant: 15402,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 165,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-enhancement",
		name: "Shaman (enhancement)",
		stage: 6,
		head_item: 18817,
		neck_item: 18404,
		shoulder_item: 21684,
		back_item: 21701,
		chest_item: 21374,
		wrist_item: 21602,
		hand_item: 21624,
		waist_item: 21586,
		leg_item: 21651,
		foot_item: 21705,
		finger1_item: 17063,
		finger2_item: 18821,
		trinket1_item: 22321,
		trinket2_item: 19289,
		mainhand_item: 21134,
		offhand_item: 0,
		range_item: 22395,
		head_enchant: 15402,
		shoulder_enchant: 24422,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	},
	{
		id: 166,
		race : null,
		faction: null,
		wowclass: "shaman",
		wowspec: "shaman-enhancement",
		name: "Shaman (enhancement)",
		stage: 7,
		head_item: 18817,
		neck_item: 18404,
		shoulder_item: 21684,
		back_item: 23045,
		chest_item: 21374,
		wrist_item: 21602,
		hand_item: 21624,
		waist_item: 21586,
		leg_item: 21651,
		foot_item: 21705,
		finger1_item: 17063,
		finger2_item: 23038,
		trinket1_item: 22321,
		trinket2_item: 19289,
		mainhand_item: 22798,
		offhand_item: 0,
		range_item: 22395,
		head_enchant: 15402,
		shoulder_enchant: 29483,
		back_enchant: 13882,
		chest_enchant: 20025,
		wrist_enchant: 20010,
		hand_enchant: 20012,
		leg_enchant: 15402,
		foot_enchant: 13890,
		mainhand_enchant: 20034,
		offhand_enchant: 0,
		range_enchant: 0
	}
  ];
}
