import React from "react";

import "./icon.css";

import druid_bg from "../../img/class/class_druid.jpg";
import hunter_bg from "../../img/class/class_hunter.jpg";
import mage_bg from "../../img/class/class_mage.jpg";
import paladin_bg from "../../img/class/class_paladin.jpg";
import priest_bg from "../../img/class/class_priest.jpg";
import rogue_bg from "../../img/class/class_rogue.jpg";
import shaman_bg from "../../img/class/class_shaman.jpg";
import warlock_bg from "../../img/class/class_warlock.jpg";
import warrior_bg from "../../img/class/class_warrior.jpg";

import druid_feral_bear_bg from "../../img/spec/spec_druid_feral_bear.jpg";
import druid_feral_cat_bg from "../../img/spec/spec_druid_feral_cat.jpg";
import druid_feral_resto_bg from "../../img/spec/spec_druid_resto.jpg";
import paladin_holy_bg from "../../img/spec/spec_paladin_holy.jpg";
import priest_holy_bg from "../../img/spec/spec_priest_holy.jpg";
import priest_shadow_bg from "../../img/spec/spec_priest_shadow.jpg";
import rogue_dagger_bg from "../../img/spec/spec_rogue_dagger.jpg";
import rogue_sword_bg from "../../img/spec/spec_rogue_sword.jpg";
import shaman_elemental_bg from "../../img/spec/spec_shaman_elemental.jpg";
import shaman_resto_bg from "../../img/spec/spec_shaman_resto.jpg";
import warrior_fury_bg from "../../img/spec/spec_warrior_fury.jpg";
import warrior_prot_bg from "../../img/spec/spec_warrior_prot.jpg";
import druid_feral_balance_bg from "../../img/spec/spec_druid_feral_balance.jpg";
import paladin_prot_bg from "../../img/spec/spec_paladin_prot.jpg";
import paladin_ret_bg from "../../img/spec/spec_paladin_ret.jpg";
import shaman_enh_bg from "../../img/spec/spec_shaman_enh.jpg";

import alliance_bg from "../../img/faction/alliance.png";
import horde_bg from "../../img/faction/horde.png";

import human_bg from "../../img/race/race_human_male.jpg";
import orc_bg from "../../img/race/race_orc_male.jpg";
import otherwarrior_bg from "../../img/race/race_otherwarrior_male.jpg";

function selectSlotBackground(slot) {
  switch (slot) {
    case "druid":
      return druid_bg;
    case "hunter":
      return hunter_bg;
    case "mage":
      return mage_bg;
    case "paladin":
      return paladin_bg;
    case "priest":
      return priest_bg;
    case "rogue":
      return rogue_bg;
    case "shaman":
      return shaman_bg;
    case "warlock":
      return warlock_bg;
    case "warrior":
      return warrior_bg;
    case "druid-feral-bear":
      return druid_feral_bear_bg;
    case "druid-feral-cat":
      return druid_feral_cat_bg;
    case "druid-resto":
      return druid_feral_resto_bg;
    case "paladin-holy":
      return paladin_holy_bg;
    case "priest-holy":
      return priest_holy_bg;
    case "priest-shadow":
      return priest_shadow_bg;
    case "rogue-sword":
      return rogue_sword_bg;
    case "rogue-dagger":
      return rogue_dagger_bg;
    case "shaman-elemental":
      return shaman_elemental_bg;
    case "shaman-resto":
      return shaman_resto_bg;
    case "warrior-fury":
      return warrior_fury_bg;
    case "warrior-prot":
      return warrior_prot_bg;
    case "alliance":
      return alliance_bg;
    case "horde":
      return horde_bg;
    case "human":
      return human_bg;
    case "orc":
      return orc_bg;
    case "other-warrior":
      return otherwarrior_bg;
	case "druid-balance":
      return druid_feral_balance_bg;
    case "paladin-protection":
      return paladin_prot_bg;
    case "paladin-retribution":
      return paladin_ret_bg;
    case "shaman-enhancement":
      return shaman_enh_bg;
    default:
      return null;
  }
}

export default class Icon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var backgroundurl = selectSlotBackground(this.props.slot);
    var borderSyle =
      this.props.iconSelected == this.props.slot ? " selected" : " qe0";

    return (
      <div
        className={"Icon" + borderSyle}
        style={{ backgroundImage: "url(" + backgroundurl + ")" }}
        onClick={this.props.onClick}
      />
    );
  }
}
