import React from "react";
import Loader from "react-loader-spinner";

import Tooltip from "../tooltip";

import "./item.css";

import Head_bg from "../../img/empty_slot/head.png";
import Neck_bg from "../../img/empty_slot/neck.png";
import Shoulder_bg from "../../img/empty_slot/shoulder.png";
import Back_bg from "../../img/empty_slot/chest.png";
import Chest_bg from "../../img/empty_slot/chest.png";
import Wrist_bg from "../../img/empty_slot/wrist.png";
import Hand_bg from "../../img/empty_slot/hand.png";
import Waist_bg from "../../img/empty_slot/waist.png";
import Leg_bg from "../../img/empty_slot/leg.png";
import Foot_bg from "../../img/empty_slot/foot.png";
import Finger1_bg from "../../img/empty_slot/finger.png";
import Finger2_bg from "../../img/empty_slot/finger.png";
import Trinket1_bg from "../../img/empty_slot/trinket.png";
import Trinket2_bg from "../../img/empty_slot/trinket.png";
import Mainhand_bg from "../../img/empty_slot/mainhand.png";
import Offhand_bg from "../../img/empty_slot/offhand.png";
import Range_bg from "../../img/empty_slot/range.png";

function selectSlotBackground(slot) {
  switch (slot) {
    case "head_item":
      return Head_bg;
    case "neck_item":
      return Neck_bg;
    case "shoulder_item":
      return Shoulder_bg;
    case "back_item":
      return Back_bg;
    case "chest_item":
      return Chest_bg;
    case "wrist_item":
      return Wrist_bg;
    case "hand_item":
      return Hand_bg;
    case "waist_item":
      return Waist_bg;
    case "leg_item":
      return Leg_bg;
    case "foot_item":
      return Foot_bg;
    case "finger1_item":
      return Finger1_bg;
    case "finger2_item":
      return Finger2_bg;
    case "trinket1_item":
      return Trinket1_bg;
    case "trinket2_item":
      return Trinket2_bg;
    case "mainhand_item":
      return Mainhand_bg;
    case "offhand_item":
      return Offhand_bg;
    case "range_item":
      return Range_bg;
    default:
      return null;
  }
}

export default class Item extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.httpGet = this.httpGet.bind(this);
    this.GetItem = this.GetItem.bind(this);
    this.GetItemResponse = this.GetItemResponse.bind(this);
    this.httpGetSpell = this.httpGetSpell.bind(this);
    this.GetSpell = this.GetSpell.bind(this);
    this.GetSpellResponse = this.GetSpellResponse.bind(this);
    this.state = {
      item_info: null,
      enchant: null,
      isLoading: false,
      enchant_id: this.props.enchant_id
    };
  }
  componentDidMount() {
    this.GetItem(this.props.item_id);
  }
  componentDidUpdate(prevProps) {
    if (this.props.enchant_id !== prevProps.enchant_id) {
      this.setState({ enchant_id: this.props.enchant_id });
    }
    if (this.props.item_id !== prevProps.item_id) {
      this.GetItem(this.props.item_id);
    } else if (this.props.enchant_id !== prevProps.enchant_id) {
      this.GetSpell(this.props.enchant_id);
    }
  }
  httpGet(theUrl) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, true);
    xmlHttp.send(null);
    xmlHttp.onreadystatechange = e => {
      this.GetItemResponse(xmlHttp.responseText);
    };
  }
  GetItem(id) {
    if (id === 0) {
      this.setState({ item_info: null, enchant: null });
      return;
    }
    this.setState({ isLoading: true });
    this.httpGet("item/" + id + ".xml");
  }
  GetItemResponse(xml) {
    var convert = require("xml-js");
    var resultJsonString = convert.xml2json(xml, {
      compact: true,
      spaces: 1
    });
    var objJson = JSON.parse(resultJsonString);
    if (objJson.wowhead !== undefined) {
      this.setState({ item_info: objJson.wowhead.item, isLoading: false });
      this.GetSpell(this.state.enchant_id);
    }
  }
  httpGetSpell(theUrl) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, true);
    xmlHttp.send(null);
    xmlHttp.onreadystatechange = e => {
      this.GetSpellResponse(xmlHttp.responseText);
    };
  }
  GetSpell(id) {
    if (id === 0 || id === undefined) {
      this.setState({ enchant: null });
      return;
    }
    this.httpGetSpell("spell/" + id + ".xml");
  }
  GetSpellResponse(xml) {
    if (xml !== undefined) {
      this.setState({ enchant: xml });
    }
  }
  handleClick() {
    if (this.state.item_info != null) {
      Tooltip.setTooltip(this.state.item_info, this.state.enchant);
    }
  }
  render() {
    var backgroundurl = selectSlotBackground(this.props.slot);
    var borderSyle = "";
    if (this.state.item_info !== null) {
      backgroundurl = "icon/" + this.state.item_info.icon._text + ".jpg";
      borderSyle = " qe" + this.state.item_info.quality._attributes.id;
    }
    return (
      <div
        className={"Item" + borderSyle}
        style={{
          backgroundImage: "url(" + backgroundurl + ")"
        }}
        onClick={this.handleClick}
      >
        {this.state.isLoading === false ? (
          <div />
        ) : (
          <Loader
            classname="loader"
            type="TailSpin"
            color="#FFFFFF"
            height="50"
            width="50"
          />
        )}
      </div>
    );
  }
}
